
import { defineComponent } from "vue";
export default defineComponent({
  name: "declaracion-responsable",
  components: {},
  data() {
    return { value: false };
  },
  emits: ["update:modelValue"],
  props: {
    id: { type: String, default: "" },
    name: String,
    modelValue: Boolean,
    html: String,
  },

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const myModalEl = document.getElementById(this.id);
    if (myModalEl) {
      myModalEl.addEventListener("hide.bs.modal", () => {
        self.$emit("update:modelValue", self.value);
      });
    }
  },
});
